import { AssetType, KnowledgeCategory, Language } from './enums';

export const ASSET_TABLE_HEADERS = [
  { name: 'Name/Details', width: 'w-[45%]' },
  { name: 'Workspace', width: 'w-[20%]' },
  { name: 'Created by', width: 'w-[15%]' },
  { name: 'Created on', width: 'w-[20%]' },
];

export const ASSET_INFO = {
  [AssetType.RAW_FILE]: {
    name: 'Documents',
  },
  [AssetType.LOGO]: {
    name: 'Logos',
    fileTypes: ['png'],
  },
  [AssetType.FONT]: {
    name: 'Fonts',
    fileTypes: ['ttf', 'otf'],
  },
  [AssetType.COLORS]: {
    name: 'Colors',
  },
  [AssetType.PRODUCT]: {
    name: 'Images',
    fileTypes: ['png', 'jpg', 'jpeg', 'webp'],
  },
  [AssetType.VIDEO]: {
    name: 'Videos',
    fileTypes: ['mp4', 'mov', 'mpg', 'mpeg', 'avi', 'webm', 'wmv', 'mp2'],
  },
  [AssetType.KNOWLEDGE]: {
    name: 'Attributes',
  },
};

export const UNSUPPORTED_FILE_EXT_ERROR =
  'Invalid file type uploaded. Please upload files of type ';

export const KNOWLEDGE_OPTIONS = {
  [KnowledgeCategory.DIGITAL_MANDATORIES]: 'Digital mandatories',
  [KnowledgeCategory.BRAND_PURPOSE]: 'Brand purpose',
  [KnowledgeCategory.BRAND_HISTORY]: 'Brand history',
  [KnowledgeCategory.SEO_BEST_PRACTICES]: 'Seo best practices',
  [KnowledgeCategory.BRAND_COMMUNICATION_IDEA]: 'Brand communication idea',
  [KnowledgeCategory.BRAND_PERSONALITY]: 'Brand personality',
  [KnowledgeCategory.BRAND_AMBITION]: 'Brand ambition',
  [KnowledgeCategory.BRAND_BENEFITS]: 'Brand benefits',
  [KnowledgeCategory.BRAND_CONSUMER]: 'Brand consumer',
  [KnowledgeCategory.BRAND_LOVE_KEY]: 'Brand love key',
  [KnowledgeCategory.PRODUCT_DESCRIPTION]: 'Product description',
};

export const DEFAULT_LANGUAGE = { id: Language.ENGLISH, label: 'English' };

export const LANGUAGE_OPTIONS = [
  DEFAULT_LANGUAGE,
  { id: Language.ARABIC, label: 'Arabic' },
  { id: Language.BENGALI, label: 'Bengali' },
  { id: Language.BOSNIAN, label: 'Bosnian' },
  { id: Language.CATALAN, label: 'Catalan' },
  { id: Language.CHINESE, label: 'Chinese (Simplified)' },
  { id: Language.TRADITIONAL_CHINESE, label: 'Chinese (Traditional)' },
  { id: Language.CROATIAN, label: 'Croatian' },
  { id: Language.CZECH, label: 'Czech' },
  { id: Language.DANISH, label: 'Danish' },
  { id: Language.DUTCH, label: 'Dutch' },
  { id: Language.FINNISH, label: 'Finnish' },
  { id: Language.FRENCH, label: 'French' },
  { id: Language.GERMAN, label: 'German' },
  { id: Language.GREEK, label: 'Greek' },
  { id: Language.GUJARATI, label: 'Gujarati' },
  { id: Language.HEBREW, label: 'Hebrew' },
  { id: Language.HINDI, label: 'Hindi' },
  { id: Language.HUNGARIAN, label: 'Hungarian' },
  { id: Language.INDONESIA, label: 'Indonesian' },
  { id: Language.ITALIAN, label: 'Italian' },
  { id: Language.JAPANESE, label: 'Japanese' },
  { id: Language.KANNADA, label: 'Kannada' },
  { id: Language.KOREAN, label: 'Korean' },
  { id: Language.MALAY, label: 'Malay' },
  { id: Language.MALAYALAM, label: 'Malayalam' },
  { id: Language.MARATHI, label: 'Marathi' },
  { id: Language.NORWEGIAN, label: 'Norwegian' },
  { id: Language.POLISH, label: 'Polish' },
  { id: Language.PORTUGUESE, label: 'Portuguese' },
  { id: Language.PUNJABI, label: 'Punjabi' },
  { id: Language.ROMANIAN, label: 'Romanian' },
  { id: Language.RUSSIAN, label: 'Russian' },
  { id: Language.SERBIAN, label: 'Serbian' },
  { id: Language.SLOVAKIAN, label: 'Slovakian' },
  { id: Language.SLOVENIAN, label: 'Slovenian' },
  { id: Language.SPANISH, label: 'Spanish' },
  { id: Language.SWEDISH, label: 'Swedish' },
  { id: Language.TAGALOG, label: 'Tagalog' },
  { id: Language.TAMIL, label: 'Tamil' },
  { id: Language.TELUGU, label: 'Telugu' },
  { id: Language.THAI, label: 'Thai' },
  { id: Language.TURKISH, label: 'Turkish' },
  { id: Language.UKRAINIAN, label: 'Ukrainian' },
  { id: Language.VIETNAMESE, label: 'Vietnamese' },
];
