import { FC, useMemo, useState } from 'react';

import { isNil } from 'lodash';
import { useRecoilValue } from 'recoil';

import { Trash } from '@assets/icons';
import { Button, CopyInviteLink, Select, Spinner } from '@components/index';
import { workspace } from '@store/atoms/workspace';

import {
  DATA_HEADERS,
  MANAGE_TEAM_TABLE_HEADERS,
  manageTeamConstants,
} from '../constants';
import AddUserModal, { SelectRoleRefComponent } from './AddUserModal';
import { useGetRoles } from '../hooks/use-get-roles';
import { useGetUsers } from '../hooks/use-get-users';
import { Role, UserDetails } from '../types';
import { formatRoleName } from '../utils';

const UserRow: FC<{ user: UserDetails; roleList: Role[] }> = ({
  user,
  roleList,
}) => {
  const onOptionSelect = () => {};

  const selectedRole = roleList?.find(
    role => role?.label.toLowerCase() === user?.roleName.toLowerCase(),
  );

  const renderColumn = (field: string) => {
    switch (field) {
      case manageTeamConstants.roleName:
        return (
          <Select
            options={roleList}
            selectedOption={selectedRole}
            onOptionSelect={onOptionSelect}
            PopperRefComponent={SelectRoleRefComponent}
            classOverride="min-w-[72px] max-h-[215px]"
            optionLabel={option => option.label}
            optionKey={option => option.id}
          />
        );
      case manageTeamConstants.inviteLink:
        return <CopyInviteLink inviteLink={user.inviteLink || ''} />;
      default:
        return user[field as keyof UserDetails] || '-';
    }
  };

  return (
    <tr className="h-16 border-gray-6 text-md border-b font-medium">
      {DATA_HEADERS.map(field => (
        <td
          key={field}
          className="border-r px-6 py-4 border-gray-6 break-words"
        >
          {renderColumn(field)}
        </td>
      ))}
      <td className="border-r px-6 py-4 border-gray-6 break-words">
        <div className="flex items-center justify-center size-full">
          <Trash className="size-6" />
        </div>
      </td>
    </tr>
  );
};

const ManageTeamTable = () => {
  const currentWorkspace = useRecoilValue(workspace);

  const [isOpen, setIsOpen] = useState(false);

  const { data: rolesData } = useGetRoles();

  const { data: users, isLoading } = useGetUsers(
    currentWorkspace?.id,
    !!currentWorkspace?.id,
  );

  const roleList = useMemo(() => {
    if (rolesData)
      return rolesData?.map(({ id, role_name }) => ({
        id,
        label: formatRoleName(role_name as string),
      }));
  }, [rolesData]);

  if (isNil(currentWorkspace) || isLoading) {
    return (
      <div className="group flex justify-between items-center h-8 w-full px-2 rounded-lg cursor-pointer focus:outline-none">
        <div className="flex w-10">
          <Spinner color="bg-yellow-3" />
        </div>
      </div>
    );
  }

  return (
    <>
      {!isNil(currentWorkspace) && !isLoading && (
        <div className="flex flex-col px-8 py-6 overflow-auto h-[calc(100vh-122px)]">
          <div className="border border-gray-6 rounded-t-lg rounded-tr-lg px-8 py-6 text-md">
            <div className="flex justify-between items-center">
              <span className="text-gray-3">
                Showing {users?.length} team members
              </span>
              <Button
                label="Add new user"
                classOverride="!w-[180px] !font bold"
                variant="contained"
                onClick={() => setIsOpen(true)}
              />
            </div>
          </div>
          <div className="border-l border-r border-gray-6">
            <table className="table-fixed min-w-full border-collapse">
              <thead className="border-b border-gray-6">
                <tr>
                  {MANAGE_TEAM_TABLE_HEADERS.map((item, index) => (
                    <th
                      key={index}
                      className="px-6 py-2 border-r border-b border-gray-6 text-left w-[20%]"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {users?.map(user => (
                  <UserRow
                    key={user.userId}
                    user={user}
                    roleList={roleList as Role[]}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {isOpen && (
            <AddUserModal
              isOpen={isOpen}
              onCloseModal={() => setIsOpen(false)}
              roleList={roleList as Role[]}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ManageTeamTable;
