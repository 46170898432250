import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { EllipsisGrid, PdfIcon } from '@assets/icons';
import { MoreOptions, Spinner } from '@components/index';
import { AssetType } from '@pages/home-page/enums';
import { DataItem } from '@pages/home-page/types';
import { workspace } from '@store/atoms/workspace';
import { toDateFormat } from '@utils/date';

import KnowledgeItems from './KnowledgeItems';

interface AssetDataItemsProps {
  dataItems: DataItem[];
  isLoading?: boolean;
  imageClassName: string;
  handleDelete: (id: number, assetType: AssetType) => void;
}

const DocumentItem = ({
  dataItem,
  workspace,
  handleDelete,
}: {
  dataItem: DataItem;
  workspace: string;
  handleDelete: (id: number, assetType: AssetType) => void;
}) => {
  const { name, createdBy, createdAt } = dataItem;

  const onDeleteIconClick = () => {
    handleDelete(dataItem.id, dataItem.assetType);
  };

  return (
    <div className="w-full flex flex-1 flex-row rounded border border-gray-2 p-2 items-center mb-2">
      <div className="w-[48%] flex flex-row gap-4 items-center">
        <EllipsisGrid className="h-4 w-4 flex-shrink-0" />
        <PdfIcon className="h-4 w-4 flex-shrink-0" />
        <div className="text-sm">{name}</div>
      </div>
      <div className="w-[20%] text-sm">{workspace}</div>
      <div className="w-[15%] text-sm pl-5">{createdBy}</div>
      <div className="w-[20%] text-sm pl-10">
        {toDateFormat(createdAt) || '-'}
      </div>
      <div className="flex flex-1 justify-end">
        <MoreOptions onClickDelete={onDeleteIconClick} />
      </div>
    </div>
  );
};

const AssetDataItems: FC<AssetDataItemsProps> = ({
  dataItems,
  isLoading,
  imageClassName,
  handleDelete,
}) => {
  const currentWorkspace = useRecoilValue(workspace);

  const renderDetailItem = (item: DataItem) => {
    if ([AssetType.LOGO, AssetType.PRODUCT].includes(item.assetType)) {
      return (
        <div className="ml-2">
          <img
            src={item?.url}
            alt={`${item.assetType}_${item.id}`}
            className={imageClassName}
          />
        </div>
      );
    }

    if (item.assetType === AssetType.COLORS) {
      return (
        <div className="flex items-center border border-gray-2 px-2 py-1 w-max rounded">
          <div
            className="w-6 h-6 rounded-full m-1.5 drop-shadow mr-2.5 border"
            style={{ background: item.details }}
          />
          {item.details}
        </div>
      );
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  const getGroupedKnowledgeItems = useMemo(() => {
    return (dataItems: DataItem[]) => {
      const groupedItems = dataItems.reduce(
        (knowledgeCategory: { [key: string]: DataItem[] }, item: DataItem) => {
          const name = item.name;
          if (!knowledgeCategory[name]) {
            knowledgeCategory[name] = [];
          }
          knowledgeCategory[name].push(item);

          return knowledgeCategory;
        },
        {},
      );

      return groupedItems;
    };
  }, [dataItems]);

  return (
    <>
      {dataItems[0]?.assetType === AssetType.KNOWLEDGE ? (
        <KnowledgeItems
          knowledgeDataItems={getGroupedKnowledgeItems(dataItems)}
          workspace={currentWorkspace.name}
          handleDelete={handleDelete}
        />
      ) : (
        dataItems?.map(item => {
          if (item.assetType === AssetType.RAW_FILE)
            return (
              <tr key={item.id}>
                <td className="px-2" colSpan={4}>
                  <DocumentItem
                    dataItem={item}
                    workspace={currentWorkspace.name}
                    handleDelete={handleDelete}
                  />
                </td>
              </tr>
            );

          return (
            <tr className="w-full" key={item.id}>
              <td className="pl-5 py-2">
                <div className="flex flex-row gap-4 items-center text-sm">
                  {item?.name}
                  <div className="pl-5 hyphens-auto">
                    {renderDetailItem(item)}
                  </div>
                </div>
              </td>
              <td className="py-2">
                <div className="text-sm">{currentWorkspace?.name}</div>
              </td>
              <td className="text-sm py-2">{item?.createdBy}</td>
              <td>
                <div className="flex flex-row justify-between pr-3">
                  <div className="text-sm py-2">
                    {toDateFormat(item?.createdAt) || '-'}
                  </div>
                  <MoreOptions
                    onClickDelete={() => handleDelete(item.id, item.assetType)}
                  />
                </div>
              </td>
            </tr>
          );
        })
      )}
    </>
  );
};
export default AssetDataItems;
