import { FC, useMemo, useState } from 'react';

import { Document } from '@assets/icons';
import { Modal } from '@components/index';
import { RetrievedDocument } from '@hooks/types';

interface DocumentPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentList: RetrievedDocument[];
}
const DocumentPreviewModal: FC<DocumentPreviewModalProps> = ({
  isOpen,
  onClose,
  documentList,
}) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState(
    documentList[0]?.id,
  );

  const fileNames = documentList.map((document: RetrievedDocument) => ({
    id: document.id,
    file_name: document.file_name,
  }));

  const { fileName, pageNumber, pageContent } = useMemo(() => {
    const filteredDocs = documentList.filter(
      document => document.id === selectedDocumentId,
    );

    const selectedDocument = filteredDocs[0]; // Get the first match

    return {
      fileName: selectedDocument?.file_name || '',
      pageNumber: selectedDocument?.page_number || null,
      pageContent: selectedDocument?.page_content || null,
    };
  }, [selectedDocumentId, documentList]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-row w-[55vw] h-[70vh]">
        <div className="flex flex-col gap-2 border-r p-5 w-[25%] overflow-auto">
          <div className="flex flex-row gap-2 items-center text-sm mb-2">
            <Document className=" stroke-dark-2" />
            Source document
          </div>
          {fileNames.map(fileData => {
            const selectedFileClassOverride =
              fileData.id === selectedDocumentId ? 'bg-gray-2 rounded-md' : '';

            return (
              <div
                key={fileData.id}
                className={`text-sm cursor-pointer p-2 ${selectedFileClassOverride}`}
                onClick={() => setSelectedDocumentId(fileData.id)}
              >
                {fileData.file_name}
              </div>
            );
          })}
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between border-b p-5">
            <div className="text-base font-semibold">{fileName}</div>
            <div className="text-sm border p-1 rounded mr-10">{`Page no. ${pageNumber}`}</div>
          </div>
          <div className="p-4 text-sm overflow-auto">
            {pageContent?.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentPreviewModal;
