import { FC } from 'react';

import { SpinnerHoc } from '@hocs/spinner/SpinnerHoc';

export interface ButtonProps {
  variant?: 'outlined' | 'contained' | 'text' | 'error';
  type?: 'button' | 'submit';
  IconLeftComponent?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement>
  > | null;
  IconRightComponent?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement>
  > | null;
  id?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  classOverride?: string;
}

const Button: FC<ButtonProps> = ({
  variant = 'outlined',
  type = 'button',
  IconLeftComponent = () => null,
  IconRightComponent = () => null,
  id = '',
  isDisabled = false,
  isLoading = false,
  label,
  onClick = () => null,
  classOverride = '',
}) => {
  let buttonClasses = '';
  let buttonDisabledClasses =
    'h-12 w-full text-sm text-gray-3 bg-gray-2 cursor-default';
  let iconClasses = '';
  let iconDisabledClasses = 'h5 w-5 text-gray-3';

  switch (variant) {
    case 'outlined':
      iconClasses = 'h-5 w-5';
      buttonClasses =
        'h-12 w-full border border-gray-1 text-sm font-semibold hover:bg-dark-1 hover:bg-opacity-[8%] transition duration-200';
      break;
    case 'text':
      iconClasses = 'h-5 w-5 text-black';
      buttonClasses = 'h-fit flex text-yellow-3 font-semibold text-xs';
      break;
    case 'contained':
      iconClasses = 'h-5 w-5 text-white';
      buttonClasses =
        'h-12 w-full bg-yellow-1 border border-yellow-1 text-sm text-white hover:bg-opacity-[80%]';
      break;
    case 'error':
      iconClasses = 'text-red-1';
      buttonClasses = 'h-10 w-full text-red-1 bg-red-4';
      break;
  }

  return (
    <button
      className={`flex-none rounded focus:outline-none ${isDisabled ? buttonDisabledClasses : buttonClasses} ${classOverride}`}
      disabled={isDisabled}
      onClick={onClick}
      {...(id && { id })}
      type={type}
    >
      <SpinnerHoc color="bg-gray-1" isLoaded={!isLoading}>
        <span
          className={
            'w-full h-full flex items-center whitespace-nowrap space-x-2 justify-center'
          }
        >
          {IconLeftComponent && (
            <IconLeftComponent
              className={isDisabled ? iconDisabledClasses : iconClasses}
            />
          )}
          {label && <span className="truncate">{label}</span>}
          {IconRightComponent && (
            <IconRightComponent
              className={isDisabled ? iconDisabledClasses : iconClasses}
            />
          )}
        </span>
      </SpinnerHoc>
    </button>
  );
};

export default Button;
