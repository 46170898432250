import { Fragment } from 'react';

import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { isEmpty } from 'lodash';

import { Check } from '@assets/icons';

import { Popper, Spinner } from '..';

export interface SelectProps {
  classOverride?: string;
  selectedOption?: { id: number; label: string };
  popperPlacement?: 'bottom-start' | 'bottom-end' | 'right-start';
  PopperRefComponent: ({
    label,
    isOpen,
  }: {
    label: string;
    isOpen: boolean;
  }) => JSX.Element;
  options: { id: number; label: string }[];
  onOptionSelect: (option: { id: number; label: string }) => void;
  optionLabel?: (option: { id: number; label: string }) => string;
  optionKey?: (option: { id: number; label: string }) => number;
  isLoading?: boolean;
}

const Select = ({
  options,
  selectedOption,
  onOptionSelect,
  popperPlacement = 'bottom-end',
  PopperRefComponent,
  classOverride = '',
  optionLabel = (option: { id: number; label: string }) => option.label,
  optionKey = (option: { id: number; label: string }) => option.id,
  isLoading = false,
}: SelectProps) => {
  return (
    <Listbox value={selectedOption ?? null} onChange={onOptionSelect}>
      {({ open }) => (
        <Popper
          placement={popperPlacement}
          PopComponent={({ popperElRef, setPopperElement, width }: any) => (
            <Transition
              afterLeave={() => {
                setPopperElement(null);
              }}
              as={Fragment}
              beforeEnter={() => setPopperElement(popperElRef.current)}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={open}
            >
              <ListboxOptions
                className={`flex flex-col border border-gray-2 bg-white rounded shadow-lg focus:outline-none ${classOverride}`}
                static
                style={{ width }}
              >
                {isLoading && (
                  <div className="p-6">
                    <Spinner />
                  </div>
                )}
                {!isLoading && isEmpty(options) && (
                  <div className="flex justify-center p-6 text-sm text-gray-1">
                    No data found
                  </div>
                )}
                {!isLoading && !isEmpty(options) && (
                  <span className="flex-grow overflow-auto">
                    {options.map((option: { id: number; label: string }) => {
                      const isSelected =
                        optionKey(option) ===
                        (selectedOption ? optionKey(selectedOption) : '');

                      return (
                        <ListboxOption key={optionKey(option)} value={option}>
                          <span className="flex items-center justify-between cursor-pointer focus:select-none pl-4 h-11 border-b border-gray-2 hover:bg-yellow-2 pr-2">
                            <span
                              className={'text-sm block truncate flex-grow'}
                            >
                              {optionLabel(option)}
                            </span>
                            <div className="flex items-center">
                              <Check
                                className={`flex-none text-yellow-1 ${isSelected ? '' : 'invisible'}`}
                              />
                            </div>
                          </span>
                        </ListboxOption>
                      );
                    })}
                  </span>
                )}
              </ListboxOptions>
            </Transition>
          )}
          RefComponent={() => (
            <PopperRefComponent
              label={selectedOption ? optionLabel(selectedOption) : ''}
              isOpen={open}
            />
          )}
        />
      )}
    </Listbox>
  );
};

export default Select;
