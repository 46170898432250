import { FC, Fragment, useCallback, useRef } from 'react';

import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { debounce, isEmpty, isNil } from 'lodash';
import { Waypoint } from 'react-waypoint';

import { BreadCrumbs, Input, Popper, Spinner } from '@components/index';

import WorkspaceOptionItem from './WorkspaceOptionItem';
import { useWorkspaceHierarchy } from '../hooks/use-workspace-hierarchy';
import { Workspace } from '../types';

interface SelectWorkspaceDropdownProps {
  options: Workspace[];
  selectedOption?: any;
  hasMoreOptions?: boolean;
  isLoadingOptions?: boolean;
  fetchMoreOptions?: () => void;
  onOptionSelect: (option: Workspace) => void;
  setSearchKeyword: (keyword: string | null) => void;
  activeParentId: number | null;
  setActiveParentId: (parentId: number | null) => void;
  popperPlacement?: 'bottom-start' | 'bottom-end' | 'right-start';
  PopperRefComponent: ({
    label,
    isOpen,
  }: {
    label: string;
    isOpen: boolean;
  }) => JSX.Element;
  classOverride?: string;
  optionClassOverride?: string;
  children?: React.ReactNode;
}

const SelectWorkspaceDropdown: FC<SelectWorkspaceDropdownProps> = ({
  options,
  selectedOption,
  hasMoreOptions,
  isLoadingOptions,
  fetchMoreOptions,
  onOptionSelect,
  setSearchKeyword,
  activeParentId,
  setActiveParentId,
  popperPlacement = 'bottom-end',
  PopperRefComponent,
  classOverride = '',
  optionClassOverride = '',
  children,
}) => {
  const searchInput = useRef<HTMLInputElement>(null);

  const handleSearch = useCallback(
    debounce((workspaceName: string) => {
      setSearchKeyword(workspaceName);
      setActiveParentId(null);
    }, 500),
    [],
  );

  const handleFetchChildren = (workspaceId: number | null) => {
    setSearchKeyword(null);
    if (searchInput.current) {
      searchInput.current.value = '';
    }
    setActiveParentId(workspaceId);
  };

  const resetOptions = () => {
    setSearchKeyword(null);
    setActiveParentId(null);
  };

  const { data: clientPath, isLoading: isLoadingClientPath } =
    useWorkspaceHierarchy({
      clientId: activeParentId,
      enabled: !!activeParentId,
    });

  const isLoadingData = isLoadingOptions || isLoadingClientPath;

  return (
    <Listbox value={selectedOption} onChange={onOptionSelect}>
      {({ open }) => (
        <Popper
          placement={popperPlacement}
          PopComponent={({ popperElRef, setPopperElement, width }: any) => (
            <Transition
              afterLeave={() => {
                setPopperElement(null);
                resetOptions();
              }}
              as={Fragment}
              beforeEnter={() => setPopperElement(popperElRef.current)}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={open}
            >
              <ListboxOptions
                className={`flex flex-col border border-gray-2 bg-white rounded shadow-lg focus:outline-none ${classOverride}`}
                static
                style={{ width }}
              >
                <div className="p-2">
                  <Input
                    ref={searchInput}
                    placeholder="Search"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();

                      return handleSearch(e.target?.value ?? '');
                    }}
                    withSearchIcon
                  />
                </div>
                {!isNil(activeParentId) && !isLoadingClientPath && (
                  <BreadCrumbs
                    idPath={clientPath?.hierarcicalClientIdPath || ''}
                    namePath={clientPath?.hierarcicalClientNamePath || ''}
                    onOptionClick={handleFetchChildren}
                  />
                )}
                {isLoadingData && (
                  <div className="p-6">
                    <Spinner />
                  </div>
                )}
                {!isLoadingData && isEmpty(options) && (
                  <div className="flex justify-center p-6 text-sm text-gray-1">
                    No workspaces found
                  </div>
                )}
                {!isLoadingData && !isEmpty(options) && (
                  <span className="flex-grow overflow-auto">
                    {options.map((option: Workspace) => (
                      <ListboxOption key={option.id} value={option}>
                        <WorkspaceOptionItem
                          option={option}
                          isSelected={option.id === selectedOption?.id}
                          onNextClick={e => {
                            e.stopPropagation();
                            handleFetchChildren(option.id);
                          }}
                          classOverride={optionClassOverride}
                        />
                      </ListboxOption>
                    ))}
                    {hasMoreOptions && (
                      <Waypoint
                        onEnter={() => {
                          if (hasMoreOptions && fetchMoreOptions) {
                            fetchMoreOptions();
                          }
                        }}
                      >
                        <div className="relative pb-2 h-10">
                          <div className="absolute inset-0 flex flex-col items-center justify-center">
                            <Spinner />
                          </div>
                        </div>
                      </Waypoint>
                    )}
                  </span>
                )}
                {children}
              </ListboxOptions>
            </Transition>
          )}
          RefComponent={() => (
            <PopperRefComponent
              label={selectedOption?.name || ''}
              isOpen={open}
            />
          )}
        />
      )}
    </Listbox>
  );
};

export default SelectWorkspaceDropdown;
