import { FC } from 'react';

import { ASSET_INFO, ASSET_TABLE_HEADERS } from '@pages/home-page/constants';
import { AssetType } from '@pages/home-page/enums';
import { DataItem, GroupedBrandData } from '@pages/home-page/types';
import { doesValueExist, filterResults } from '@pages/home-page/utils';

import CollapsibleAssetItem from './CollapsibleAssetItem';

interface AssetTableProps {
  searchKeyword: string;
  isAllItemsCollpased: boolean;
  groupedBrandData: GroupedBrandData;
}

const AssetTable: FC<AssetTableProps> = ({
  searchKeyword,
  isAllItemsCollpased,
  groupedBrandData,
}) => {
  const { brandAssets, isLoadingBrandAssets } = groupedBrandData;

  const getDataItems = (id: AssetType) => {
    switch (id) {
      case AssetType.LOGO:
        return filterResults(searchKeyword, brandAssets.logoItems);
      case AssetType.FONT:
        return filterResults(searchKeyword, brandAssets.fontItems);
      case AssetType.COLORS:
        return filterResults(searchKeyword, brandAssets.colorItems);
      case AssetType.RAW_FILE:
        return searchKeyword
          ? brandAssets?.documentItems?.filter(
              (item: DataItem) =>
                doesValueExist(item.name || '', searchKeyword) ||
                doesValueExist(item.createdBy, searchKeyword) ||
                doesValueExist(item.createdAt, searchKeyword),
            )
          : brandAssets.documentItems;
      case AssetType.PRODUCT:
        return filterResults(searchKeyword, brandAssets.imageItems);
      case AssetType.VIDEO:
        return filterResults(searchKeyword, brandAssets.videoItems);
      case AssetType.KNOWLEDGE:
        return filterResults(searchKeyword, brandAssets.knowledgeItems);
      default:
        return [];
    }
  };

  const getAssetRow = (assetType: AssetType) => {
    return {
      id: assetType,
      header: ASSET_INFO[assetType].name,
      tooltipMessage: '',
      dataItems: getDataItems(assetType),
      imageClassName: `object-fill rounded ${
        assetType === AssetType.LOGO ? 'h-10 bg-gray-2 p-1.5' : 'h-[84px]'
      }`,
    };
  };

  return (
    <div className="border rounded-lg mt-1">
      <table className="w-full">
        <thead>
          <tr className="text-left text-dark-1">
            {ASSET_TABLE_HEADERS.map(
              (item: { name: string; width: string }) => (
                <th
                  key={item.name}
                  className={`pl-5 h-11 bg-gray-7 text-sm text-dark-1 font-semibold ${item.width}`}
                >
                  {item.name}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody className="w-full">
          {Object.keys(AssetType).map(assetType => {
            const assetItem = getAssetRow(assetType as AssetType);

            return (
              <CollapsibleAssetItem
                key={assetType}
                assetItem={assetItem}
                isAllItemsCollpased={isAllItemsCollpased}
                isLoading={isLoadingBrandAssets}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AssetTable;
