import { ChangeEvent, FC, forwardRef, useState } from 'react';

import { EyeClosed, EyeOpen, Search } from '@assets/icons';
import { InputTypes } from '@enums/input';

interface InputProps {
  id?: string;
  name?: string;
  placeholder?: string;
  type?: InputTypes;
  label?: string;
  value?: string;
  customInputClass?: string;
  error?: any;
  register?: Function;
  validations?: object;
  showErrorMessage?: boolean;
  withSearchIcon?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id = '',
      name = '',
      placeholder = '',
      type = InputTypes.TEXT,
      label = '',
      value,
      customInputClass = '',
      error = '',
      register,
      validations,
      showErrorMessage = true,
      withSearchIcon = false,
      onChange = () => {},
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const errorClasses = error ? '!border-red-1 !focus:border-red-1' : '';

    const toggleShowPassword = () => setShowPassword(!showPassword);

    return (
      <div className="space-y-1">
        {label && (
          <label htmlFor={name} className="text-sm">
            {label}
          </label>
        )}
        <div className="relative">
          {withSearchIcon && (
            <Search className="absolute h-full text-gray-5 left-4 top-1/2 transform -translate-y-1/2" />
          )}
          <input
            ref={ref}
            {...(register ? register(name, validations) : {})}
            id={id}
            name={name}
            {...(value && { value })}
            placeholder={placeholder}
            type={
              type === InputTypes.PASSWORD
                ? showPassword
                  ? InputTypes.TEXT
                  : InputTypes.PASSWORD
                : type
            }
            onChange={onChange}
            className={`h-10 w-full text-sm placeholder-gray-3 rounded focus:outline-none border border-gray-4 py-3 px-4 ${
              withSearchIcon ? 'pl-10' : ''
            } ${customInputClass} ${errorClasses}`}
          />
          {error && showErrorMessage && (
            <div className="text-red-1 text-xs mt-1">{error}</div>
          )}
          {type === InputTypes.PASSWORD && (
            <label
              htmlFor={name}
              className="flex items-center h-10 absolute inset-y-0 right-0 cursor-pointer px-2"
            >
              {showPassword ? (
                <EyeOpen onClick={toggleShowPassword} className="text-gray-1" />
              ) : (
                <EyeClosed
                  onClick={toggleShowPassword}
                  className="text-gray-1"
                />
              )}
            </label>
          )}
        </div>
      </div>
    );
  },
);

export default Input;
