import { useState } from 'react';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { AssetBank, Cross } from '@assets/icons';
import { Button, Input } from '@components/index';

const ID_LIMIT = 25;

const AssetIdsButton = () => {
  const [assetIds, setAssetIds] = useState<string>('');
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [isOverLimit, setIsOverLimit] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [50, 10],
        },
      },
    ],
  });

  const handleImport = () => {
    const distinctIds = assetIds
      .replaceAll(' ', '')
      .split(',')
      .reduce((list: string[], current: string) => {
        if (!list.includes(current)) {
          list.push(current.toLowerCase().trim());
        }

        return list;
      }, []);
    if (distinctIds.length > ID_LIMIT) {
      setIsOverLimit(true);
    } else {
      setIsOverLimit(false);
    }
  };

  return (
    <Popover>
      {() => (
        <>
          <div>
            <PopoverButton
              ref={
                setReferenceElement as (
                  instance: HTMLButtonElement | null,
                ) => void
              }
              as="button"
            >
              <Button
                label="The Asset Bank"
                IconLeftComponent={AssetBank}
                classOverride="border-gray-4 p-2 font-normal"
              />
            </PopoverButton>
          </div>
          <PopoverPanel
            className="shadow-xl p-4 bg-white w-[260px] rounded-md flex flex-col z-10 gap-4"
            ref={element => setPopperElement(element as HTMLDivElement | null)}
            style={styles.popper}
            {...attributes.popper}
          >
            <PopoverPanel className="text-black absolute right-4 top-4 z-10">
              {({ close }) => (
                <Button
                  variant="text"
                  IconLeftComponent={Cross}
                  onClick={() => {
                    setAssetIds('');
                    close();
                  }}
                />
              )}
            </PopoverPanel>
            <PopoverPanel as="div">
              <span className="text-sm">Enter Asset ID(s)</span>
            </PopoverPanel>
            <Input
              value={assetIds}
              placeholder="Eg: 12345, 23456, etc..."
              onChange={e => setAssetIds(e.target.value)}
              error={isOverLimit && `You've entered over ${ID_LIMIT} IDs`}
            />
            <PopoverButton as="div">
              <Button
                variant="contained"
                label="Import"
                isDisabled={assetIds.length === 0}
                onClick={handleImport}
              />
            </PopoverButton>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default AssetIdsButton;
